@font-face {
  font-family: 'otomanopee';
  src: url('../assets/otomanopee/fonts/ttf/OtomanopeeOne-Regular.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap');

.team-body {
  padding-right: 0;
  margin: 0;
  width: 100%;
  height: 120vh;
  background-image: url('../assets/team_bg.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  background-attachment: fixed;
}

.team-box {
  display: flex;
  flex-direction: column;
  width: 75%;
  height: 100vh;
  padding: 2rem;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(7px);
  border-radius: 28.8px;
  border: 0.9px solid #FFF;
  overflow: hidden;
}

.heading-team {
  color: yellow;
  font-size: 50px;
  margin-bottom: 2rem;
  font-family: 'otomanopee';
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(10rem, 1fr));
  justify-items: center;
  width: 75%;
}

.team-card {
  background-size: contain;
  display: flex;
  align-items: center;
  position: relative;
  width: 12rem;
  height: 20vh;
  background-repeat: no-repeat;
  background-image: '../assets/id.png';
  background-image: cover;
  background-position: center;
  text-align: right;
  color: #fff;
  font-family: Arial, sans-serif;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.team-card img {
  width: 3.3rem;
  height: 4.1rem;
  object-fit: cover;
}

.team-card:hover {
  transform: scale(1.2);
}

.profile-image {
  position: absolute;
  transform: translateX(-50%);
  left: 3rem;
  top: 2.2rem;
}


.member-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  top: 1.6rem;
  right: 1.5rem;
  font-size: 0.8rem;
  font-family: "Orbitron", sans-serif;

}

.member-name,
.member-position,
.member-detail {
  margin: 5px 0;
  padding: 0;
  font-family: "Orbitron", sans-serif;
}

.member-position, .member-detail{
  font-size: 0.7rem;

}


@media (max-width: 1000px) {
  .team-grid {
    grid-template-columns: repeat(3, minmax(10rem, 1fr));
  }
  .team-box {
    width: 80%;
    padding: 1.5rem;
  }
  .heading-team {
    font-size: 50px;
  }
  .team-body{
    height: 150vh;
  }
  .team-box{
    height: 130vh;
  }
  .profile-image{
    left: 35px; 
    top: 17px;
  }

  .team-card img{
    height: 2.5rem ;
    width: 2rem ;
  }
  .member-info{
    top: 6px;
  }

}

@media (max-width: 900px) {
  .team-grid {
    grid-template-columns: repeat(2, minmax(10rem, 1fr));
  }
  .team-box {
    width: 90%;
    padding: 1rem;
  }
  .heading-team {
    font-size: 45px;
  }
  .team-body{
    height: 170vh;
  }
  .team-box{
    height: 150vh;
  }
}

@media (max-width: 750px) {
  .team-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
  .team-box {
    width: 100%;
    padding: 1rem;
  }
  .team-card {
    width: 10rem;
    height: 15vh;
  }
  .heading-team {
    font-size: 40px;
  }
  .team-body{
    height: 100vh;
  }
  .team-box{
    height: 90vh;
  }
}

@media (max-width: 660px) {
  .team-grid {
    grid-template-columns: repeat(1, minmax(10rem, 1fr));
  }
  .team-box {
    width: 100%;
    padding: 0.5rem;
    height: 175vh;
  }
  .team-card {
    width: 8rem;
    height: 12vh;
  }
  .heading-team {
    font-size: 35px;
  }
  .team-body{
    height: 190vh;
  }
  .team-card img{
    height: 2.7rem ;
    width: 2.2rem ;
  }
  .profile-image{
    left: 33px;
    top: 30px;
  }
  .member-info{
    top: 20px;
  }
  .member-name,
  .member-position,
  .member-detail {
    font-size: 8px;
  }
}

/* @media(max-width: 500px){
  .profile-image{
    left: 35px; 
    top: 17px;
  }
  .team-card img{
    height: 2.5rem ;
    width: 2rem ;
  }
  .member-info{
    top: 5px;
  }
  .member-name,
  .member-position,
  .member-detail {
    font-size: 7px;
  }
} */

@media(max-width: 600px){
  .profile-image{
    left: 35px; 
    top: 17px;
  }

  .team-card img{
    height: 2.5rem ;
    width: 2rem ;
  }
  .member-info{
    top: 6px;
  }
  .member-name,
  .member-position,
  .member-detail {
    font-size: 7px;
  }

}

@media(max-width: 440px){
  .profile-image{
    left: 32px; 
    top: 32px;
  }

  .team-card img{
    height: 2.6rem ;
    width: 2.2rem ;
  }
  .member-info{
    top: 20px;
  }
  .member-name,
  .member-position,
  .member-detail {
    font-size: 7px;
  }

}

@media(max-width: 400px){
  .profile-image{
    left: 32px; 
    top: 26px;
  }

  .team-card img{
    height: 2.8rem ;
    width: 2.2rem ;
  }
  .member-info{
    top: 15px;
  }
  .member-name,
  .member-position,
  .member-detail {
    font-size: 7px;
  }

}

@media(max-width: 380px){
  .profile-image{
    left: 33px; 
    top: 18px;
  }

  .team-card img{
    height: 2.6rem ;
    width: 2.2rem ;
  }
  .member-info{
    top: 8px;
  }
  .member-name,
  .member-position,
  .member-detail {
    font-size: 7px;
  }

}





