@font-face {
    font-family: 'otomanopee';
    src: url('../assets/otomanopee/fonts/ttf/OtomanopeeOne-Regular.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

.about-body{
    width: 100%;
    height: 100vh;
    background-image: url('../assets/about_bg.png');
    background-image: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    background-attachment: fixed;
    
}

.translucent-box{
    display: flex;
    flex-direction: column;
    width: 30rem;
    height: 4rem;
    padding: 13rem;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    border: 0.88px solid #FFE600;
    background: rgba(252, 254, 125, 0.20);
    backdrop-filter: blur(5px);
    gap: 3rem;
    overflow: hidden;
}
.translucent-box:hover{
    backdrop-filter: blur(8px);
}
.about-section{
    color: yellow;
    font-size: 50px;
    font-family: 'otomanopee';
}

.about-para{
    color: white;
    font-size: 20px;
    font-family: 'otomanopee';
    width: 40rem;
}

@media(max-width: 990px){

    .translucent-box{
        width: 20rem;
        gap: 1rem;

    }
    .about-section{
        font-size: 40px;
    }
    .about-para{
        font-size: 20px;
        width: 24rem;
        padding: 1rem;
    }
    .about-section{
        font-size: 32px;
        margin-top: 5rem;
    }
    .about-para{
        font-size: 17px;
        width: 24rem;
        margin: 1rem 4rem 3rem 4rem;
    }

}

@media(max-width: 800px){
    .translucent-box{
        width: 10rem;
    }
    .about-section{
        font-size: 32px;
        margin-top: 5rem;
    }
    .about-para{
        font-size: 17px;
        width: 24rem;
        margin: 1rem 4rem 3rem 4rem;
    }
}

@media(max-width: 600px){
    .translucent-box{
        width: 4rem;
    }
    .about-section{
        font-size: 32px;
        margin-top: 5rem;
    }
    .about-para{
        font-size: 17px;
        width: 24rem;
        margin: 1rem 4rem 3rem 4rem;
    }
}

@media(max-width: 440px){
    .translucent-box{
        width: 1%;
    }
    .about-section{
        font-size: 32px;
        margin-top: 5rem;
    }
    .about-para{
        font-size: 17px;
        width: 24rem;
        margin: 1rem 4rem 3rem 4rem;
    }
}