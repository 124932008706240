@import url('https://fonts.googleapis.com/css2?family=DotGothic16&display=swap');

.timer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .timer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px; 
  }
  
  .timer-section {
    text-align: center;
  }
  
  .timer-double {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .timer-double-square {
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  
  .timer-value {
    background-color: #50008F;
    color: white;
    padding: 10px;
    font-size: 2em;
    border-radius: 7px;
    margin: 2px;
    font-family: "DotGothic16", sans-serif;
  }
  
  .timer-label {
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #ffffff;
    font-family: "DotGothic16", sans-serif;
  }
  
  /* .start-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1em;
    background-color: #50008F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .start-button:hover {
    background-color: #6b35af;
  }
   */

   @media(max-width: 500px ){
    .timer-double{
      gap: 0.7px;

    }
    .timer-value{
      font-size: 15px;
    }
    .timer-label{
      font-size: 16px;
    }
   }