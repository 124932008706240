@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

*{
    margin: 0;
}
.home-body{
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100vh;
    background-image: url('../assets/home_bg.png');
    background-image: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-attachment: fixed;
}

.div{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5rem;
}

.box{
    top: 40%;
    width: 50%;
    padding: 5rem;
    border-radius: 45px;
    border: 1.44px solid #83B0FF;
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(8.640000343322754px);

}

.gif-paradox{
    height: 12rem;
    background-image: url('../assets/paradox-gif.gif');
    background-size: cover;
}

.buttons{
    display: flex;
    flex-direction: column;
}


.google-button, .discord-button{
    border-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    background-color: transparent;
    width: 35%;  
    border-radius: 3.6px;
    border: 0.9px solid #FFF;
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    line-height: normal;
    padding: 0.3em;
    margin: 0.2em;   
}

.google-button:hover , .discord-button:hover {
    background-color: white;
    color: black;
    transition-duration: 0.4s;
    transition-timing-function: ease-out;
}

.discord-button{
    margin-top: 6px;
}

@media(max-width: 1200px){
    .gif-paradox{
        height: 10rem;   
        padding: 3px;
    }
}
@media(max-width: 1000px){
    .gif-paradox{
        height: 9rem;
    }
    .discord-button{
        margin-top: 6px;
    }
}

@media(max-width: 900px ){
    .box{
        padding: 2rem;
        border-radius: 30px;
        margin-top: -50px;
    }
    .gif-paradox{
        height: 7rem;   
        padding: 3px;
    }
    .google-button, .discord-button{
        width: 120px;
        font-size: 10px;
    }

    .discord-button{
        margin-top: 6px;
    }

    .discord-icon, .google-icon{
        height: 13px;
        width: 13px;
    }  
    
}


@media(max-width: 700px){
    .gif-paradox{
        height: 6rem;   
    }
}

@media(max-width: 630px){
    .gif-paradox{
        height: 5rem;  
    }
}

@media(max-width: 500px){
    .gif-paradox{
        height: 4rem;  
    }
}

@media(max-width: 420px){
    .gif-paradox{
        height: 3rem;  
    }
    .google-button, .discord-button{
        width: 100px;
        font-size: 8px;
    }

    .discord-icon, .google-icon{
        height: 10px;
        width: 10px;
    } 
}

@media(max-width: 330px){
    .gif-paradox{
        height: 1.5rem;  
        padding: 8px;
    }
    .google-button, .discord-button{
        width: 100px;
        font-size: 8px;
    }

    .discord-icon, .google-icon{
        height: 10px;
        width: 10px;
    } 
}

@media(max-width: 250px){
    .gif-paradox{
        height: 0.8rem;  
        padding: 10px;
    }
}

@media(max-width: 200px){
    .gif-paradox{
        height: 0.4rem;  
        padding: 10px;
    }
    .google-button, .discord-button{
        width: 70px;
        font-size: 6px;
    }

    .discord-icon, .google-icon{
        height: 7px;
        width: 7px;
    } 
}

