@font-face {
    font-family: 'BPdots';
    src: url('../assets/bpdots-family/bpdots.regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
  
.footer-body{
    width: 100%;
    background-image: url('../assets/footer_bg.png');
    background-image: linear-gradient(rgba(0, 0, 0, 0.5),
                       rgba(0, 0, 0, 0.5)), url("../assets/footer_bg.png");
    background-image: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.paradox{
    background: linear-gradient(92deg, #00FFA3 0%, #15DE1D 36%, #FFE600 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'BPdots';
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.56px;
    text-align: center;
    padding: 3rem;
}

.rights{
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    /* color: white;
    display: inline-block;  
    background-color: black; */
    /* background-color: rgba(0, 0, 0, 0.7); */
    padding: 1rem;
    /* background: #1a1a1a;
    filter: blur(16px); */
    font-family: Montserrat;
}

.click{
    display: flex;
    gap: 0.5rem;
}