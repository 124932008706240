@font-face {
    font-family: 'otomanopee';
    src: url('../assets/otomanopee/fonts/ttf/OtomanopeeOne-Regular.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

.faq-body{
    width: 100%;
    height: 210vh;
    background-image: url('../assets/faq_bg.png');
    background-image: linear-gradient(rgba(0, 0, 0, 0.314),
                       rgba(0, 0, 0, 0.17)), url("../assets/faq_bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    background-attachment: fixed;
}

.heading-faq{
    color: yellow;
    font-size: 60px;
    text-align: center;
    margin: 6rem;
    font-weight: 400;
    font-family: 'otomanopee';
}

.question-boxes{
    width: 70%;
    padding: 1rem;
    border-radius: 10.8px;
    border: 1.8px dashed #FFE600;
    background: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(10.799999237060547px);
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.3s ease;
    font-family: 'otomanopee';
    font-weight: 400;
    line-height: normal;
}

.question-boxes.active {
    background: rgba(0, 0, 0, 0.5);
}

.boxes{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

h4{
    color: #FFE600;
}

.downarrow{
    fill: #FFE600;
    width: 1.5rem;
    height: 1.5rem;
    align-self: flex-end;
    transition: transform 0.3s ease;
}

.question-boxes.active .downarrow {
    transform: rotate(90deg);
}

.expanded-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    padding: 0 10px;
    color: white; /* Adjust the text color to match your design */
}

.expanded-content.open {
    max-height: 5rem; /* Adjust as needed */
    padding: 15px;
}

